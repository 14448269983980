<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title primary-title>
        <h4 class="headline logo_text">
          <i class="fa fa-map-o logo_icon" />&nbsp;Delete {{ marker.name }}
        </h4>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <p />
        Are you sure you want to delete this marker?
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="deleteMarker">
          Delete
        </v-btn>
        <v-btn color="primary" text @click="dialog = false">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "DeleteMarker",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      marker: {},
      dialog: true
    };
  },
  computed: {
    ...mapGetters(["currMap"])
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$emit("close");
      }
    }
  },
  mounted() {
    this.marker = Object.assign(
      {},
      this.currMap.markers.find(marker => marker.id === this.id)
    );
    this.marker.id = this.id;
  },
  methods: {
    async deleteMarker() {
      await this.$store.dispatch("deleteMarker", this.marker.id);
      this.dialog = false;
    }
  }
};
</script>
